import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { string, number } from 'prop-types';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';

import css from './OrderBreakdown.module.css';
import IconAdd from '../IconAdd/IconAdd';
import IconMinus from '../IconMinus/IconMinus';
import ParticipantNumberField from './ParticipantNumberField';

const LineItemParticipantBasePriceMaybe = props => {
  const { lineItems, code, intl, label, quantity, onQuantityChange, fee } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.participantRate'
    : isDaily
    ? 'OrderBreakdown.participantRate'
    : isHourly
    ? 'OrderBreakdown.participantRate'
    : 'OrderBreakdown.participantRate';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return (
		<>
			<hr className={css.totalDivider} />
	    <div className={css.lineItem}>
	      <span className={css.itemLabel}>
					<span style={{ fontWeight: 'bold', fontSize: '16px' }} >{ label }</span>
					<span style={{ display: 'block', fontWeight: '400'}} >
		        {fee}€
		      </span>
	      </span>
				<ParticipantNumberField initialQuantity={parseInt(1)} onQuantityChange={onQuantityChange} />

	    </div>
		</>

  );
};

LineItemParticipantBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
	label: string.isRequired, // Add label propType
	fee: number.isRequired
};

export default LineItemParticipantBasePriceMaybe;
