import React, { useState } from 'react';
import LineItemParticipantBasePriceMaybe from './LineItemParticipantBasePriceMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import { types as sdkTypes } from '../../util/sdkLoader';
import { useDispatch } from 'react-redux';
import { setAdultQuantity } from '../../containers/ListingPage/ListingPage.duck';
import { setChildrenQuantity } from '../../containers/ListingPage/ListingPage.duck';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

const ParentComponent = ({ lineItems, lineItemUnitType, intl, transaction, isProvider, isCheckoutPage}) => {
	const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
	const dispatch = useDispatch();
	const { Money } = sdkTypes;
  const [adultQuantity, settAdultQuantity] = useState(1); // Example starting quantity
  const [childrenQuantity, settChildrenQuantity] = useState(1); // Example starting quantity
   // Rates for adults and children
  const adultRate = lineItems[1].unitPrice.amount / 100; // Example rate per adult
  const childrenRate = 20; // Example rate per child

  // Calculate additional fees
  const additionalFees = (adultQuantity * adultRate) + (childrenQuantity * childrenRate);

  // Update the quantities
  const updateAdultQuantity = (newQuantity) => {
		console.log('updated quantity');
		settAdultQuantity(newQuantity);
    dispatch(setAdultQuantity(newQuantity));
  };

  const updateChildrenQuantity = (newQuantity) => {
		settChildrenQuantity(newQuantity);
		dispatch(setChildrenQuantity(newQuantity));
  };

  // Calculate the total

	const updatedTotalPrice = transaction.attributes.payinTotal.amount + ( additionalFees * 100 );

	// Prepare a modified transaction object for LineItemTotalPrice
	console.log(transaction);
	console.log("CHECK TRANSACTION");
  const updatedTransaction = {
    ...transaction,
    attributes: {
      ...transaction.attributes,
      payinTotal: new Money(updatedTotalPrice, transaction.attributes.payinTotal.currency)
    }
  };

	console.log(updatedTransaction)
	console.log(transaction)

  return (
    <>
      {!isCheckoutPage && (
        <LineItemParticipantBasePriceMaybe
          lineItems={lineItems}
          code={lineItemUnitType}
          intl={intl}
          label="Adults"
          quantity={adultQuantity}
          onQuantityChange={updateAdultQuantity}
          fee={adultRate}
        />
      )}
      {!isCheckoutPage && (
        <LineItemParticipantBasePriceMaybe
          lineItems={lineItems}
          code={lineItemUnitType}
          intl={intl}
          label="Children"
          quantity={childrenQuantity}
          onQuantityChange={updateChildrenQuantity}
          fee={20}
        />
      )}
      <LineItemTotalPrice 
        transaction={updatedTransaction}
        isProvider={isProvider}
        intl={intl} 
      />
    </>
  );
};

export default ParentComponent;
