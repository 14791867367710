import React, { useState } from 'react';
import IconAdd from '../IconAdd/IconAdd';
import IconMinus from '../IconMinus/IconMinus';

const ParticipantNumberField = ({ initialQuantity, onQuantityChange }) => {
  const [quantity, setQuantity] = useState(initialQuantity);

  const incrementQuantity = (e) => {
		e.preventDefault();
		const newQuantity = quantity + 1;
    setQuantity(prevQuantity => prevQuantity + 1);
		onQuantityChange(newQuantity);
  };

  const decrementQuantity = (e) => {
		e.preventDefault();
    if (quantity > 0) {
			const newQuantity = quantity - 1;
      setQuantity(prevQuantity => prevQuantity - 1);
			onQuantityChange(newQuantity);
    }
  };

  return (
    <div>
      <button onClick={decrementQuantity} style={{
		    borderRadius: '100px',
		    width: '30px',
		    height: '30px',
		    lineHeight: 0.2,
		    borderColor: 'darkgray',
		    borderWidth: '1px',
		  }}><IconMinus /></button>
			<span style={{
			    paddingLeft: '10px',
			    paddingRight: '10px',
			    fontWeight: 'normal',
			    fontSize: '18px',
			}}>{quantity}</span>
      <button onClick={incrementQuantity} style={{
		    borderRadius: '100px',
		    width: '30px',
		    height: '30px',
		    lineHeight: 0.2,
		    borderColor: 'darkgray',
		    borderWidth: '1px',
		  }}><IconAdd /></button>
    </div>
  );
};

export default ParticipantNumberField;
